import { html, LitElement } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { componentStyles } from "./modal.styles";

import { watch } from "../../internal/watch";
import Modal from "../../internal/modal";
import { emitEvent } from "../../internal/event-dispatch";


/**
 * 
 * @element odj-modal
 * 
 * @event {{ trigger: HTMLElement }} odj-request-close - Dispatched when the user tries to close the modal. Call `.preventDefault()` to prevent closing of the modal.
 * @event {{ trigger: HTMLElement }} odj-modal-opened - Dispatched when the modal has been opened
 * @event {{ trigger: HTMLElement }} odj-modal-closed - Dispatched when the modal has been closed
 * @event {{ trigger: HTMLElement }} odj-modal-open - Dispatched when the modal will open
 * @event {{ trigger: HTMLElement }} odj-modal-close - Dispatched when the modal will close
 * 
 * @slot - The modal content
 * @slot label - The modal header
 * @slot footer - The modal footer (used for buttons, etc.)
 */
@customElement('odj-modal')
export class OdjModal extends LitElement {
    
    static override styles = componentStyles

    /** Whether the modal is visible */
    @property({reflect: true, type: Boolean})
    visible = false

    /** The heading of the modal */
    @property({reflect: true, type: String})
    label: string | undefined

    @property({type: String})
    size: 'md' | 'lg' | 'fullscreen' = 'md'
    
    @query('.odj-modal')
    private modalOverlay: HTMLDivElement

    private modal: Modal;
    private originalTrigger: HTMLElement | null

    private lastTrigger: HTMLElement | null = null

    connectedCallback() {
        super.connectedCallback();
        this.modal = new Modal(this);
    }

    @watch('visible', { waitUntilFirstUpdate: true })
    async handleOpenChange() {
        if (this.visible) {
            this.modal.activate()
            this.lockBodyScrolling()
            this.originalTrigger = document.activeElement as HTMLElement
            requestAnimationFrame(() => {
                this.modalOverlay.focus()
                emitEvent(this, 'odj-modal-opened', {
                    detail: {
                        trigger: this.lastTrigger
                    }
                })
            })

        } else {
            this.modal.deactivate()
            this.unlockBodyScrolling()
            const trigger = this.originalTrigger;
            if (typeof trigger?.focus === 'function') {
                setTimeout(() => trigger.focus());
            }

            emitEvent(this, 'odj-modal-closed', {
                detail: {
                    trigger: this.lastTrigger
                }
            })
        }

    }

    private lockBodyScrolling() {
        document.body.classList.add('odj-scroll-lock')
    }

    private unlockBodyScrolling() {
        document.body.classList.remove('odj-scroll-lock')

    }

    firstUpdated() {
        if (this.visible) {
            this.modal.activate()
            this.lockBodyScrolling()
        }
    }

    /** Open the modal */
    public open(trigger?: HTMLElement) {
        this.lastTrigger = trigger || (document.activeElement as HTMLElement)
        emitEvent(this, 'odj-modal-open', {
            detail: {
                trigger: this.lastTrigger
            }
        })
        this.visible = true
    }

    /** Close the modal */
    public close(trigger?: HTMLElement) {
        this.lastTrigger = trigger || (document.activeElement as HTMLElement)
        emitEvent(this, 'odj-modal-close', {
            detail: {
                trigger: this.lastTrigger
            }
        })
        this.visible = false
    }
    /** DEPRECATED: Use .close() instead */
    public hide() {
        console.warn('.hide() is deprecated. Use .close() instead.')
        this.close()
    }

    private requestClose() {
        const requestCloseEvent = emitEvent(this, 'odj-request-close', {
            cancelable: true,
            detail: {
                trigger: this
            }
        })
        
        if (!requestCloseEvent.defaultPrevented) {
            this.close(this)
        }
    }
    
    private handleKeydown(e: KeyboardEvent) {
        if (e.key === 'Esc' || e.key === 'Escape') {
            this.requestClose()
            e.preventDefault()
        }
    }

    render() {
        const classes = {
            'odj-modal': true,
            'odj-modal--shown': this.visible,
            [`odj-modal--${this.size}`]: true,
        }

        return html`<div tabindex="-1" @keydown="${this.handleKeydown}" class="${classMap(classes)}"><div class="odj-modal-overlay" tabindex="-1" @click="${() => this.requestClose()}"></div><div class="modal-container" aria-modal="true" aria-hidden="${this.visible ? 'false' : 'true'}" aria-labelledby="title" part="panel"><odj-icon class="close" icon="x" @click="${this.requestClose}"></odj-icon><div class="modal-header"><h2 id="title" part="label"><slot name="label">${this.label?.length > 0 ? this.label : String.fromCharCode(65279)}</slot></h2></div><div class="modal-content" part="content"><slot></slot></div><div class="modal-footer" part="footer"><slot name="footer"></slot></div></div></div>`
    }

}

declare global {
    interface HTMLElementTagNameMap {
      "odj-modal": OdjModal;
    }
}